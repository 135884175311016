<template>
  <button class="hl-button" type="button">
    <span v-if="text !==''" class="hl-button-text">{{ text }}</span>
    <slot />
  </button>
</template>


<script>
export default {
  name: 'HlBtn',
  props: {
    text: {
      type: String,
      default: ''
      }
  },
  data() {
    return {}
  },
  mounted() {},
    methods: {  }
}
</script>

<style scoped>
.hl-button-text {
  padding-right: 2vw;
  font-size: 1.2rem;
  font-weight: 300;
}
.hl-button {
  border: none;
  color: var(--color-black);
  padding: 5px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
}

.hl-button1:hover {
  background-color: var(--color-brand-300);
  color: var(--color-black);
}


</style>
