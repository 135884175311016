<template>
  <v-app-bar
    v-if="isAuthenticated()" flat
    class="headerbar"
  >
    <img
      src="/images/MemasLogo.png" width="200"
      class="logo" @click="goHome()"
    >
    <img
      v-if="getLoggedIn()"
      src="/images/homebutton.svg"
      height="50"
      class="logo"
      @click="goHome()"
    >
    <!--
      <v-toolbar-items v-for="(item, index) in getNavItems()" :key="index">
      <v-btn text :aria-label="item.a11y" @click="navigate(item, index)">
        <div :class="getNavClass(index)" v-text="item.name" />
      </v-btn>
    </v-toolbar-items>
    -->
    <v-spacer />
    <v-toolbar-title v-if="getNotRegistered()">
      {{ $t("gui.notregistered") }}
    </v-toolbar-title>
    <v-toolbar-title v-else>
      {{ $t("gui.adminof") }} {{ getPrimaryUserData().pu_displayName }}
    </v-toolbar-title>
    <v-spacer />
    <!--
    <HelpInformation color="var(--color-brand)" />
    -->
    <v-avatar color="var(--color-brand-300)">
      <span color="var(--color-white)">{{ getAvatarName(true) }}</span>
    </v-avatar>
    <v-btn
      icon :class="getNavClass(10)"
      @click="doSettings()"
    >
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn>
    <v-btn
      icon :class="getNavClass(20)"
      :loading="isLoading" @click="logout()"
    >
      <v-icon>mdi-logout-variant</v-icon>
    </v-btn>
  </v-app-bar>
  <v-app-bar
    v-else flat
    class="headerbar"
  >
    <img
      src="/images/MemasLogo.png" width="200"
      class="logo"
    >
    <v-spacer />
    <div class="loginbutton">
      <GoogleLogin :callback="googleCallback" />
    </div>
  </v-app-bar>
</template>

<!--
<script setup>
const callback = (response) => {
  // This callback will be triggered when the user selects or login to
  // his Google account from the popup
  console.log('Handle the response', response)
}
</scrip>
-->

<script>
// import {mapState} from 'vuex'
/*
import { OAuth2Client } from 'google-auth-library'
const client = new OAuth2Client(
  '1040330893434-er6ul24q6reiok10t2e1ak5rndvkeqqe.apps.googleusercontent.com'
)
*/
import { mapActions, mapState } from 'pinia'

import { useAdminStore } from '@/stores/adminstore.js'
import { useUserStore } from '@/stores/userstore.js'
import HelpInformation from '@/components/menues/HelpInformation.vue'
import { googleLogout } from 'vue3-google-login'
import { decodeCredential } from 'vue3-google-login'
// import GoogleLogin from '@/components/menues/GoogleLogin.vue'
// import GoogleLogout from '@/components/menues/GoogleLogout.vue'

export default {
  name: 'NavigationSystem',

  /*
  setup() {
    const adminStore = useAdminwebStore()
    return {
      // you can return the whole store instance to use it in the template
      adminStore
    }
  },
  */
  components: {
    //  NavIcon: () => import('@/components/menues/NavIcon'),
    HelpInformation //: () => import("@/components/menues/HelpInformation.vue"),
    // GoogleLogin,
    // GoogleLogout
  },
  props: {
    location: { type: String, default: 'top' }
  },

  data() {
    return {
      notRegistered: true,
      isLoading: false
    }
  },
  computed: {},
  mounted() {
    this.setActiveNavItem(2)
  },
  methods: {
    ...mapState(useAdminStore, ['getActiveNavItem', 'getUiLevel', 'getLang']),
    ...mapActions(useAdminStore, ['setActiveNavItem', 'setUiLevel', 'setLang']),
    ...mapActions(useUserStore, [
      'logoutUser',
      'findSecondaryUser',
      'setDisplayName',
      'processJWT',
      'setNotRegistered'
    ]),
    ...mapState(useUserStore, [
      'getLoggedIn',
      'getDisplayName',
      'getSecondaryUserData',
      'getPrimaryUserData',
      'getEmail',
      'getNotRegistered'
    ]),
    /*
    async verifyCredentials(credential) {
      const ticket = await client.verifyIdToken({
        idToken: credential
      })
      const payload = ticket.getPayload()
      return payload
    },
    */
    imageError(err) {
      window.alert(err)
    },
    goHome() {
      this.$router
        .push({
          name: 'LoggedIn'
        })
        .catch(() => {})
    },
    googleCallback(response) {
      console.log('GOOGLE CALLBACK GOT CALLED (%j)', response)
      this.isLoading = true
      // This callback will be triggered when the user selects or login to
      // his Google account from the popup
      this.setActiveNavItem(3)
      let cred = response.credential
      console.log('CALLBACK RESPONSE (%j)', response)
      console.log('NAV ITEM (%s)', this.getActiveNavItem())
      console.log('Handle the response', cred)
      const userData = decodeCredential(response.credential)
      console.log('Handle the userData', userData)
      /*
      this.verifyCredentials(response.credential)
        .then((userInfo) => {
          // use userInfo and do your server-side logics here
          console.log('userInfo is (%j)', userInfo)
        })
        .catch((error) => {
          // validation failed and userinfo was not obtained
        })
        */
      this.processJWT(cred).then((res) => {
        console.log('PROCESS JWT returned: ', res)
        if (res.error) {
          console.error('***ERROR*** When logging in... (%j', res)
          this.$router
            .push({
              name: 'LandingPage'
            })
            .catch(() => {})
        } else if (res.success) {
          console.log('we have successful login (%j)', res)
          console.log('EMAIL (%s)', this.getEmail())
          this.findSecondaryUser(this.getEmail()).then((result) => {
            console.log('RESULT FROM FIND SECONDARY USER IS: (%j)', result)
            let resultData = result.data || []
            console.log('RESULT DATA (%j)', resultData)
            if (resultData.error || resultData.noresult) {
              console.log('RESULT HAS NO DATA')
              this.setDisplayName(userData.name)
              this.setNotRegistered(true)
              // this.notRegistered = true
              this.isLoading = false
              this.$router
                .push({
                  name: 'UserAccounts'
                })
                .catch(() => {})
              return
            } else if (result.success) {
              this.isLoading = false
              this.setNotRegistered(false)
              this.$router
                .push({
                  name: 'LoggedIn'
                })
                .catch(() => {})
            }
          })
        }
      })
    },
    isAuthenticated() {
      return this.getLoggedIn()
    },
    getAvatarName(avatar) {
      if (avatar) {
        let name = this.getDisplayName() || 'No Name'
        let nameParts = name.split(' ')
        console.log('NAMEPARTS (%s) (%s)', nameParts[0], nameParts[1])
        if (nameParts.length > 1) {
          return (
            nameParts[0].substring(0, 1).toUpperCase() +
            nameParts[1].substring(0, 1).toUpperCase()
          )
        } else if (nameParts.length > 0) {
          return nameParts[0].substring(0, 1).toUpperCase()
        } else {
          return ''
        }
      } else {
        return this.getDisplayName()
      }
    },
    getNavClass(navIndex) {
      if (this.getActiveNavItem() === navIndex) {
        return 'label active'
      } else {
        return 'label'
      }
    },
    navigate(item, itemIndex) {
      console.log('NAVIGATE got called !')
      console.log(item)
      item.index = itemIndex
      this.setActiveNavItem(item)
      this.$router
        .push({
          name: item.pathName
        })
        .catch(() => {})
    },
    doSettings() {
      var newPath = '/settings/'
      console.log('++++++++++ NEW PATH (' + newPath + ')')
      this.setActiveNavItem(10)
      this.activeMenyItem = 10
      this.$router
        .push({
          name: 'MemasSettings'
        })
        .catch(() => {})
    },
    getNavItems() {
      return [
        {
          /* memas navigation */
          index: 0,
          name: this.$t('nav.nav0'),
          a11y: this.$t('nav.nav0'),
          pathName: 'LoggedIn',
          uiLevel: this.getUiLevel()
        },
        {
          /* 1 */
          index: 0,
          name: this.$t('nav.nav1'),
          a11y: this.$t('nav.nav1'),
          pathName: 'AboutMemas',
          uiLevel: this.getUiLevel()
        },
        {
          /* 2 */
          index: 0,
          name: this.$t('nav.nav2'),
          a11y: this.$t('nav.nav2'),
          pathName: 'PrivacyPage',
          uiLevel: this.getUiLevel()
        }
        /*
        {
          / * 2 * /
          index: 0,
          name: this.$t('nav.nav3'),
          a11y: this.$t('nav.nav3'),
          pathName: 'PrimaryLandingPage',
          uiLevel: this.getUiLevel()
        }
        */
      ]
    },
    async logout() {
      console.log('logout got called')
      try {
        this.logoutUser().then(() => {
          googleLogout()
          this.$router.push({
            name: 'LandingPage'
          })
        })
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style scoped>
.headerbar {
  margin-bottom: 1vh;
  height: 10vh;
}

.loginbutton {
  padding: 2vh 1vw 2vh 1vw;
}

.logohome {
  position: relative;
  top: 1vh;
  left: 3vw;
}
.nav {
  display: flex;
  align-items: center;
}
.nav-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
.icon {
  margin-bottom: 0.5rem;
}
.label {
  display: flex;
  font-size: 0.68rem;
  font-weight: var(--type-weight-medium);
  text-align: center;
  letter-spacing: 0.02em;
  text-decoration: none;
  cursor: pointer;
}

.active {
  font-weight: 800;
  font-size: 0.78rem;
  color: var(--color-brand);
}
</style>
