import {createApp} from 'vue'
import {createPinia} from 'pinia'
import i18n from '@/i18n/i18n-setup.js'
import vuetify from '@/plugins/vuetify.js'

import App from './App.vue'
import router from './router'
import dayjs from '@/plugins/dayjs'
import hlBtn from '@/components/hl/hlBtn.vue'
import hlSnackbar from '@/components/hl/hlSnackbar.vue'

import Toast from 'vue-toastification'
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css'

import {Buffer} from 'node:buffer'


// import '@/registerServiceWorker.js'

// @ts-ignore
window.Buffer = Buffer

//https://vite-pwa-org.netlify.app/guide/prompt-for-update.html
// import { registerSW } from 'virtual:pwa-register'

import vue3GoogleLogin from 'vue3-google-login'

import './assets/main.css'
// import './assets/main.scss'
import '@mdi/font/css/materialdesignicons.css'

import { registerSW } from 'virtual:pwa-register'

const toastOptions = {
  timeout: 4500,
  maxToasts: 10,
  newestOnTop: true,
  showCloseButtonOnHover: true,
  position: 'top-center'
}

const updateSW = registerSW({
  // immediate: true
  onNeedRefresh() {
    console.log('******************* On need refresh')
    window.alert('UPDATE AVAILABLE !')
  },
  onOfflineReady() {
    console.log('******************* On offline Ready')
    window.alert('OFFLINE READY !')
  }
})
const app = createApp(App)
const pinia = createPinia()

app.config.globalProperties.$date = dayjs
app.component('HlBtn', hlBtn)
app.component('HlSnackbar', hlSnackbar)

/*
const gAuthClientId =
  '1040330893434-er6ul24q6reiok10t2e1ak5rndvkeqqe.apps.googleusercontent.com'

app.use(gAuthPlugin, {
  clientId: gAuthClientId,
  scope: 'email',
  prompt: 'consent'
})
*/
// updateSW()

console.log('***** PROD ENVIRONMENT ***** (%s)', import.meta.env.PROD)
var googleOauthClientID = ''
if (import.meta.env.PROD) {
  // googleOauthClientID = '69131030546-kokq74rtmnsnddansf9to69tn19v3ehd.apps.googleusercontent.com'
  googleOauthClientID = '99211736731-qqtjn7sllruib3p0hqopuofgb9jh7ak6.apps.googleusercontent.com'
} else {
  // googleOauthClientID = '634213953556-61s2q9vild7oaa2nh8is6vsor50pqe98.apps.googleusercontent.com'
  // 634213953556-61s2q9vild7oaa2nh8is6vsor50pqe98.apps.googleusercontent.com
  // googleOauthClientID = '1040330893434-er6ul24q6reiok10t2e1ak5rndvkeqqe.apps.googleusercontent.com'
  googleOauthClientID = '1016843908045-9vvfro1fddmer5orb9an432aamfglj0f.apps.googleusercontent.com'
}

app.use(vue3GoogleLogin, {
  clientId: googleOauthClientID
})
/*
app.use(vue3GoogleLogin, {
  clientId: '1040330893434-er6ul24q6reiok10t2e1ak5rndvkeqqe.apps.googleusercontent.com'
})
*/

app.use(updateSW)
app.use(pinia)
app.use(router)
app.use(i18n)
app.use(vuetify)
app.use(Toast, toastOptions)

app.mount('#app')
