<template>
    <v-footer
        v-if="getLoggedIn()"
        :app="true"
        height="40px"
        color="var(--color-brand-200)"
    >
        <!-- IS AUTHENTICATED -->
        <v-btn variant="text" size="small" v-html="getEnvText()" />
        <v-btn
            variant="text"
            size="small"
            class="nametext"
            :text="loggedInAs()"
        />
        <v-spacer />
        <v-menu open-on-click>
            <template #activator="{ props }">
                <v-btn
                    icon="true"
                    v-bind="props"
                    size="small"
                    color="var(--color-brand-200)"
                >
                    <Component :is="getActiveFlag()" height="20px" />
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    v-for="(item, index) in getLangList()"
                    :key="index"
                >
                    <v-btn
                        icon="true"
                        size="small"
                        aria-label="Language"
                        @click="setLocale(item.lang)"
                    >
                        <Component
                            :is="getFlagComponent(item.lang)"
                            height="20px"
                        />
                    </v-btn>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-footer>
    <v-footer v-else app height="20px" color="var(--color-brand-200)">
        <v-btn variant="text" size="small" v-html="getEnvText()" />

        <v-spacer />
        <v-menu open-on-click>
            <template #activator="{ props }">
                <v-btn
                    :icon="true"
                    v-bind="props"
                    variant="plain"
                    size="small"
                    aria-label="Language"
                >
                    <Component :is="getActiveFlag()" height="20px" />
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    v-for="(item, index) in getLangList()"
                    :key="index"
                >
                    <v-btn
                        size="small"
                        aria-label="Choose langauge"
                        variant="plain"
                        @click="setLocale(item.lang)"
                    >
                        <Component
                            :is="getFlagComponent(item.lang)"
                            height="30px"
                            fill="var(--color-brand-200)"
                        />
                    </v-btn>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-footer>
</template>

<script>
import { mapActions, mapState } from 'pinia'

import { useAdminStore } from '@/stores/adminstore.js'
import { useUserStore } from '@/stores/userstore.js'

import app from '../../../package.json'
import nbFlag from '@/assets/svgs/flags/nbFlag.vue'
import enFlag from '@/assets/svgs/flags/enFlag.vue'
import seFlag from '@/assets/svgs/flags/seFlag.vue'
import roFlag from '@/assets/svgs/flags/roFlag.vue'
import itFlag from '@/assets/svgs/flags/itFlag.vue'
import deFlag from '@/assets/svgs/flags/deFlag.vue'
import chFlag from '@/assets/svgs/flags/chFlag.vue'
import frFlag from '@/assets/svgs/flags/frFlag.vue'

export default {
    name: 'FooterMenu',
    // props: ['auth', 'authenticated'],
    data() {
        return {
            flagComponentsMap: new Map()
        }
    },
    mounted() {
        let localeLang = localStorage.getItem('lang')
        console.info('Mounted lang (%s) (%s)', this.$i18n.locale, localeLang)
        if (localeLang) {
            this.$root.$i18n.locale = localeLang
            let webLang = get3DigitLangCode(this.$i18n.locale)
            // console.log('=== WEB LANG === (%o)', webLang)
            this.setLang(webLang)
            this.$date.locale(this.$root.$i18n.locale)
        } else {
            console.log('Mounted lang (%s)', this.$i18n.locale)
            let webLang = get3DigitLangCode(this.$i18n.locale)
            // console.log('=== WEB LANG === (%o)', webLang)
            localStorage.setItem('lang', this.$i18n.locale)
            this.setLang(webLang)
            this.$date.locale(this.$root.$i18n.locale)
        }
        let countryList = this.getLanguageList() || []
        for (let i = 0; i < countryList.length; i++) {
            let item = countryList[i]
            this.flagComponentsMap.set(item.lang, item.flag)
        }
    },
    beforeMount() {},
    methods: {
        ...mapState(useUserStore, ['getLoggedIn', 'getDisplayName']),
        ...mapState(useAdminStore, ['getLanguageList']),
        ...mapActions(useAdminStore, ['setLang']),
        getEnvText() {
            return `&copy; v(${this.getEnv()})`
        },
        getFlagComponent(lang) {
            // console.log('getFalg compnentn fot called (%s)', lang)
            if (this.flagComponentsMap.has(lang)) {
                //  console.log('HAS component (%s)', this.flagComponentsMap.get(lang))
                return this.getActiveFlagComponent(lang)
            } else {
                return enFlag
            }
        },
        getActiveFlag() {
            let locale = this.$root.$i18n.locale
            return this.getActiveFlagComponent(locale)
        },
        getActiveFlagComponent(lang) {
            switch (lang) {
                case 'en':
                    return enFlag
                case 'nb':
                    return nbFlag
                case 'se':
                    return seFlag
                case 'ro':
                    return roFlag
                case 'it':
                    return itFlag
                case 'fr-ch':
                    return chFlag
                case 'fr':
                    return frFlag
                case 'de':
                    return deFlag
                case 'ro':
                    return roFlag
                default:
                    return enFlag
            }
        },

        getLangList() {
            let locale = this.$root.$i18n.locale
            let langList = []
            let countryList = this.getLanguageList()
            for (let i = 0; i < countryList.length; i++) {
                let item = countryList[i]
                if (item.lang !== locale) {
                    langList.push(item)
                }
            }
            return langList
        },
        setLocale(lang) {
            console.log(
                'setLocal got called (%s) (%s)',
                this.$root.$i18n.locale,
                lang
            )
            this.$root.$i18n.locale = lang
            this.$date.locale(this.$root.$i18n.locale)
            this.setLang(lang)
            localStorage.setItem('lang', lang)
            this.$router.go()
        },
        loggedInAs() {
            var displayName = this.getDisplayName()
            return displayName
        },
        getEnv() {
            return app.version
        },
        getColor(lang) {
            const selectedLang = localStorage.getItem('hyplang')
            if (lang === selectedLang) {
                return 'blue darken-2'
            }
            return ''
        }
    }
}

function get3DigitLangCode(lang2) {
    switch (lang2.toLowerCase()) {
        case 'nb':
            return 'nob'
        case 'nn':
            return 'nno'
        case 'en':
            return 'eng'
        case 'no':
            return 'nor'
        case 'se':
            return 'sme'
        case 'it':
            return 'ita'
        case 'fr':
            return 'fra'
        case 'de':
            return 'deu'
        case 'ro':
            return 'rom'
        case 'fr-ch':
            return 'fra'
        default:
            return 'eng' // default language
    }
}
</script>

<style lang="css">
.footer {
    text-align: left;
    font-size: calc(0.6em + 1vmin);
    color: rgb(100, 100, 100);
}

.footerinfo {
    text-align: left;
    font-size: calc(0.6em + 1vmin);
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(100, 100, 100);
}

.languagechooser {
    text-align: left;
    font-size: calc(0.6em + 1vmin);
    margin-left: 20px;
    color: rgb(100, 100, 100);
}

.hostinfo {
    padding-left: 20px;
    text-align: left;
    font-size: calc(0.6em + 0.7vmin);
    color: rgb(100, 100, 100);
}

.domainserver {
    padding-left: 10px;
    font-size: calc(0.3em + 0.3vmin);
    color: rgb(100, 100, 100);
}

.nametext {
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: capitalize;
}
</style>
