<template>
  <svg
    id="flag-icon-css-ch"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 480"
    :height="height"
  >
    <g fill-rule="evenodd" stroke-width="1pt">
      <path fill="#d52b1e" d="M0 0h640v480H0z" />
      <g fill="#fff">
        <path d="M170 195h300v90H170z" />
        <path d="M275 90h90v300h-90z" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ChFlag',
  props: {
    height: {
      type: String,
      default: '20px'
    }
  }
}
</script>
