/*
 * Library for encoding and decoding a JWT/JWE token
 * This is used for securing all communication between the web client (SPA)
 * and the express server that works as middelware between the web-client and
 * database backend - (dGraph)
 * The idea is that this library is to be used by both the client and the server.
 * (c) Hypatia Learning AS, Erlend Øverby
 * Based on JOSE library https://www.npmjs.com/package/node-jose
 * https://codeburst.io/securing-tokens-with-help-from-jose-33d8c31835a1
 * https://connect2id.com/products/nimbus-jose-jwt/examples
 */

import jose from 'node-jose'
import {SignJWT, generalDecrypt} from 'jose'

// import { jwt } from 'jsonwebtoken'

// var Base64 = require('js-base64').Base64

// import { Buffer } from 'buffer'
import {encode, decode} from '@/lib/base64'

// import crypto from 'node:crypto'

// var generateRSAKeypair = require('generate-rsa-keypair')

export class CreateHypatiaJWE {
  /*
   * Payload: datastructure to be part of signed token
   * Secret: secret used to encode and encrypt the package
   */
  constructor(payload, secret, privateKey, publicKey) {
    /*
     * privateKey is used for decryption -- in the express server
     * publicKey is used for encrypting  -- in the web-client
     */
    console.log('------------------------ Constructor : (' + secret + ')')
    // console.log(publicKey)
    // console.log(payload)
    this.payload = payload
    this.secret = secret
    // let pair = generateRSAKeypair()
    // this.privateKey = pair.private
    // this.publicKey = pair.public
    // this.privateKey = orgPrivateKey
    // this.publicKey = orgPublicKey
    this.privateKey = privateKey
    this.publicKey = publicKey
    this.keyPrivateKey = {}
    this.keyPublicKey = {}
    this.store = {}
    // console.log('------- PRIVATE ------')
    // console.log(this.privateKey)
    // console.log('-------- PUBLIC -------')
    // console.log(this.publicKey)
    // console.log('====== CONSTRUCTOR DONE ========== ')
    // console.log(publicKey)
    this.keyPublicKey = publicKey

    // console.log('====== CONSTRUCTOR DONE ========== ')
    // console.log(this.payload)
    //  console.log(this.keyPublicKey)
  }

  setUp() {
    // console.info('setUp got called!')
    // console.log(this.publicKey)
    return new Promise((resolve, reject) => {
      //resolve({ success: true })

      this.store = jose.JWK.createKeyStore()
      // this.store = createKeyStore()
      // console.log('------- STORE')
      // console.log(this.store)
      this.store
        .generate('RSA', 2048, {
          kty: 'RSA',
          alg: 'AES-GCM', // 'RS256',
          use: 'enc',
          key_ops: ['sign', 'decrypt', 'encrypt', 'unwrap']
        })
        .then((key) => {
          // console.log('------- GENERATE STORE ------')
          // console.log(key)
          if (this.publicKey !== null && this.publicKey !== undefined) {
            //  console.log('======= PUBLIC KEY ==========')
            this.store
              .add(this.publicKey, 'pem')
              .then((key) => {
                //       console.log('------- KEY -----')
                //       console.log(key)
                this.keyPublicKey = key
                // console.log('KEY: ', key)
                resolve('OK')
              })
              .catch((error) => {
                console.error('*** ERROR *** public key : ' + error)
                reject(new Error('*** ERROR *** public key : ' + error))
              })
          }
          if (this.privateKey !== null && this.privateKey !== undefined) {
            //  console.log('======= PRIVATE KEY ==========')
            this.store
              .add(this.privateKey, 'pem') // 'pem' and keygen...
              .then((key) => {
                // console.log('------- KEY -----')
                // console.log(key)
                this.keyPrivateKey = key
                resolve('OK')
              })
              .catch((error) => {
                console.error('*** ERROR *** private KEY : ' + error)
                reject(new Error('*** ERROR *** private KEY : ' + error))
              })
          }
        })
        .catch((error) => {
          console.error('*** ERROR CREATE STORE *** : ' + error)
          reject(new Error('*** ERROR CREATE STORE *** : ' + error))
        })
    })
  }

  encrypt(rawData) {
    // console.log('ENCRYPT got called: ', rawData)
    return new Promise((resolve) => {
      let strBuffer = str2ab(rawData)
      //   console.log('strBuffer: ', strBuffer)
      jose.JWE.createEncrypt(this.keyPublicKey)
        .update(strBuffer)
        .final()
        .then((encrypted) => {
          //    console.log('== ENCRYPTED ==')
          //   console.log(encrypted)
          resolve(encode(encrypted))
        })
    }) // promise
  }

  sign_old2(payload) {
    // Load the public key into JWK format
    return new Promise((resolve) => {
      //let keystore = jose.JWK.createKeyStore()
      //keystore.add(this.publicKey, 'pem').then(() => {
      let key = this.store.toJSON().keys[0]
      jose.JWS.createSign({alg: 'AES-GCM', kty: 'RSA'}, key)
        .update(jose.util.asBuffer(JSON.stringify(payload)))
        .final()
        .then((result) => {
          console.log('SIGN RES: ', result)
          resolve(result)
        })
      // })
    })
  }

  sign(payload, serverSecret) {
    //  console.log('SIGN got called')
    //  console.log(this.publicKey)
    return new Promise((resolve) => {
      // importPKCS8(this.publicKey, 'RS256').then((pubKey) => {
      //   console.log('Pub KEY : ', pubKey)
      let secret = new TextEncoder().encode(serverSecret)
      new SignJWT(payload)
        .setProtectedHeader({alg: 'HS256', enc: 'JWT'})
        .setIssuedAt()
        .setIssuer('Karde AS')
        .setAudience('MEMAS')
        .setExpirationTime('6h')
        //.sign(pubKey)
        .sign(secret)
        .then((signed) => {
          //   console.log('SIGNED: ', signed)
          resolve(signed)
        })
      //  }) // import
    }) // promise
  }

  sign_old(payload, secret) {
    console.log('SIGN got called')
    console.log(this.keyPublicKey)
    return new Promise((resolve) => {
      console.log(this.store.toJSON())
      let pubKey = this.keyPublicKey.toJSON()
      console.log(pubKey)
      let key = this.store.get(pubKey.kid)
      console.log('KEY: ', key)
      jose.JWS.createSign({kty: 'RSA', alg: 'AES-GCM'}, key)
        .update(JSON.stringify(payload))
        .final()
        .then((token) => {
          console.log('token: ', token)
          resolve(token)
        })
      /*
      jwt.sign(payload, secret, { algorithm: 'RS256' }, (err, token) => {
        if (err) {
          console.info('*** ERROR *** problems signing token : ' + err)
          reject(new Error('*** ERROR *** problems signing token : ' + err))
        }
        console.log('SIGNED ', token)
        resolve(token)
      })
      */
      /*
      let algorithm = {
        name: 'RSA-PSS',
        saltLength: 128 //the length of the salt
      }
      window.crypto.subtle
        .sign(algorithm, importPrivateKey(this.publicKey), payload)
        .then((token) => {
          resolve(token)
        })
        .catch((error) => {
          console.error('*** ERROR *** COULD NOT SIGN ', error)
          resolve({ error: true, errorMsg: 'Couldnot SIGN' })
        })
        */
    }) // promise
  }

  decrypt(encryptedMsg) {
    //  console.log('======= DECRYPT =======')
    // console.log(encryptedMsg)
    return new Promise((resolve, reject) => {
      try {
        if (!encryptedMsg) reject(new Error('Missing data to decrypt !'))
        if (!this.privateKey) reject(new Error('Missing a private Key !'))
        const decoded = decode(encryptedMsg)
        /* // node-jose version
        jose.JWE.createDecrypt(this.keyPrivateKey)
          .decrypt(decoded)
          .then((result) => {
            // console.log('== DECRYPTED ==')
            // console.log(result)
            // console.log(decode(result.plaintext))
            // console.log(decode(result.payload))
            resolve(result)
          })
          */

        // jose version

        generalDecrypt(decoded, this.keyPublicKey).then((result) => {
          //   console.log('== DECRYPTED ==')
          //   console.log(result)
          //   console.log(decode(result.plaintext))

          resolve(result)
        })
        /*
        const { plaintext, protectedHeader, additionalAuthenticatedData } =
          await jose.generalDecrypt(decoded, this.keyPublicKey)

        console.log(protectedHeader)
        const decoder = new TextDecoder()
        console.log(decoder.decode(plaintext))
        console.log(decoder.decode(additionalAuthenticatedData))

        //        resolve(JSON.parse(payload))
        */
      } catch (error) {
        console.error('**** DECRYPT *** had some problems !' + error)
        reject(new Error('**** DECRYPT *** had some problems !' + error))
      }
    }) // promise
  }

  /*
    signPayload() {
      let bufferOriginal = Buffer.from(JSON.parse(this.payload).data)
      console.log('== Buffer of payload ==' + JSON.stringify(this.payload))
      console.log(bufferOriginal)
      let key =
        JWS.createSign(key).
      update(input).
      final().
      then(function(result) {
        // {result} is a JSON object -- JWS using the JSON General Serialization
        console.log('== SIGNING result ==')
        console.log(result)
        this.resultToken = result
      })
    }
  */
  /*
  getEncryptionKeys() {
    return generateRSAKeypair()
  }
  */
}

// module.exports = CreateHypatiaJWE

/*
https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/importKey#examples

Import a PEM encoded RSA private key, to use for RSA-PSS signing.
Takes a string containing the PEM encoded key, and returns a Promise
that will resolve to a CryptoKey representing the private key.
*/
function importPrivateKey(pem) {
  // fetch the part of the PEM string between header and footer
  // console.log('Importing key')
  // console.log(pem)
  //return new Promise((resolve) => {
  const pemHeader = '-----BEGIN PRIVATE KEY-----'
  const pemFooter = '-----END PRIVATE KEY-----'
  const pemContents = pem.substring(pemHeader.length, pem.length - pemFooter.length)
  // console.log(pemContents)
  // base64 decode the string to get the binary data
  const binaryDerString = window.atob(pemContents)
  // convert from a binary string to an ArrayBuffer
  const binaryDer = str2ab(binaryDerString)
  //  console.log(binaryDer)

  return window.crypto.subtle.importKey(
    'pkcs8',
    binaryDer,
    {
      name: 'RSA-OAEP',
      hash: {
        name: 'SHA-256'
      }
    },
    true,
    ['sign', 'encrypt', 'decrypt']
  )
  /*
      .then((key) => {
        console.log('import private key resolved', key)
        resolve(key)
      })
      */
  /// }) // promise
}

/*
Convert a string into an ArrayBuffer
from https://developers.google.com/web/updates/2012/06/How-to-convert-ArrayBuffer-to-and-from-String
*/
function str2ab(str) {
  const buf = new ArrayBuffer(str.length)
  const bufView = new Uint8Array(buf)
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i)
  }
  return buf
}
