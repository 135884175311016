export const userGetters = {
  getTest(state) {
    return state.test
  },
  getLoggedIn(state) {
    return state.loggedin
  },
  getNotRegistered(state) {
    return state.notregistered
  },
  getDisplayName: (state) => {
    return state.displayname
  },
  getPrimaryUserSelected(state) {
    return state.primaryselected
  },
  getPrimaryUserData(state) {
    return state.primaryuser
  },
  getAccessToken(state) {
    return state.accesstoken
  },
  getIdToken(state) {
    return state.idtoken
  },
  getAccessTokenExpores(state) {
    return state.accesstokenexpires
  },
  getUserId(state) {
    return state.userid
  },
  getEmail(state) {
    return state.email
  },
  getAffilitation(state) {
    return state.affilitation
  },
  getServerAccessToken(state) {
    return state.serveraccesstoken
  },
  getSecondaryUserData(state) {
    return state.secondaryuser
  },
  getEngageUser(state) {
    console.info('getEngageUser called (%j)', state.engageuser)
    return state.engageuser
  }
}
