import {createRouter, createWebHistory} from 'vue-router'
import {useUserStore} from '@/stores/userstore'

const LandingPage = () => import('@/views/LandingPage.vue')
const MemasSettings = () => import('@/views/MemasSettings.vue')
const EndUser = () => import('@/views/EndUser.vue')
// const PrimaryUser = () => import('@/components/users/PrimaryUser.vue')
const NewPrimaryUser = () => import('@/components/users/NewPrimaryUser.vue')
const SelectService = () => import('@/views/SelectService.vue')
const SystemSetup = () => import('@/views/SystemSetup.vue')
const CalendarManagement = () => import('@/views/CalendarManagement.vue')
const UserLogs = () => import('@/views/UserLogs.vue')
const UserManuals = () => import('@/views/UserManuals.vue')
const UserAccounts = () => import('@/views/UserAccounts.vue')
const SelfReporting = () => import('@/views/SelfReporting.vue')
const BrainTrain = () => import('@/views/BrainTrain.vue')
const DailyHelp = () => import('@/views/DailyHelp.vue')
const PleasureEntertainment = () => import('@/views/PleasureEntertainment.vue')
const ContactInformation = () => import('@/views/ContactInformation.vue')
const LoggedIn = () => import('@/views/LoggedIn.vue')
const Callback = () => import('@/views/LoginCallback.vue')
const AboutMemas = () => import('@/views/AboutMemas.vue')
const PrivacyPage = () => import('@/views/PrivacyPage.vue')
const ErrorPage = () => import('@/views/ErrorPage.vue')
const MemasAdminWebCalendar = () => import('@/components/calendar/MemasAdminWebCalendar.vue')

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage
    },
    {
      path: '/enduser',
      name: 'EndUser',
      component: EndUser
    },
    /*
    {
      path: '/puser',
      name: 'PrimaryUser',
      component: PrimaryUser
    },
        
    {
      path: '/newpuser',
      name: 'NewPrimaryUser',
      component: NewPrimaryUser
    },
    */
    {
      path: '/userlogs',
      name: 'UserLogs',
      component: UserLogs,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/system',
      name: 'SystemSetup',
      component: SystemSetup,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/selectservice',
      name: 'SelectService',
      component: SelectService,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/usermanuals',
      name: 'UserManuals',
      component: UserManuals
    },
    {
      path: '/useraccounts',
      name: 'UserAccounts',
      component: UserAccounts
    },
    {
      path: '/calendarmanagement',
      name: 'CalendarManagement',
      component: CalendarManagement,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/selfreporting',
      name: 'SelfReporting',
      component: SelfReporting,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/braintrain',
      name: 'BrainTrain',
      component: BrainTrain,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/dailyhelp',
      name: 'DailyHelp',
      component: DailyHelp,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/pleasure',
      name: 'PleasureEntertainment',
      component: PleasureEntertainment,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/contact',
      name: 'ContactInformation',
      component: ContactInformation
    },
    {
      path: '/loggedin',
      name: 'LoggedIn',
      component: LoggedIn
    },
    {
      path: '/about',
      name: 'AboutMemas',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: AboutMemas
    },
    {
      path: '/settings',
      name: 'MemasSettings',
      component: MemasSettings
    },
    {
      path: '/privacy',
      name: 'PrivacyPage',
      component: PrivacyPage
    },
    {
      path: '/calendar',
      name: 'MemasAdminWebCalendar',
      component: MemasAdminWebCalendar,
      beforeEnter: primaryUserRegistered
    },
    {
      path: '/Error',
      name: 'ErrorPage',
      component: ErrorPage
    },
    {
      path: '/callback',
      name: 'Callback',
      component: Callback,
      props: (route) => ({
        query: route.query.code
      })
    }
  ]
})

function primaryUserRegistered(to, from, next) {
  console.log('NAVIGATING TO (%j)', to)
  let uStore = useUserStore()
  if (uStore.primaryuser.uid !== '' && uStore.primaryuser.displayName !== '') {
    next()
  } else {
    next({name: 'EndUser'})
  }
}
export default router
