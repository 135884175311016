<template>
  <svg
    id="flag-icon-css-fr"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 480"
    :height="height"
  >
    <g fill-rule="evenodd" stroke-width="1pt">
      <path fill="#fff" d="M0 0h640v480H0z" />
      <path fill="#00267f" d="M0 0h213.3v480H0z" />
      <path fill="#f31830" d="M426.7 0H640v480H426.7z" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'FrFlag',
  props: {
    height: {
      type: String,
      default: '20px'
    }
  }
}
</script>
