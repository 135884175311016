// import VueI18n from 'vue-i18n'
import { createI18n } from 'vue-i18n'
// import i18nMessages from './lang'

import nb from './lang/nb.json'
import en from './lang/en.json'
import it from './lang/it.json'
import fr from './lang/fr.json'
import ro from './lang/ro.json'

const i18nMessages = {
  en,
  nb,
  it,
  fr,
  ro
}

console.log('i18nMessages')

const defaultLanguage = 'en'
const i18n = createI18n({
  locale: defaultLanguage, // set locale
  fallbackLocale: 'en',
  messages: {
    'nb-NO': i18nMessages.nb,
    nb: i18nMessages.nb,
    en: i18nMessages.en,
    'en-GB': i18nMessages.en,
    it: i18nMessages.it,
    fr: i18nMessages.fr,
    ro: i18nMessages.ro
  } // set locale messages
})

export default i18n
