export const adminState = {
  test: 'data from pinia adminState',
  activenavitem: -1,
  uilevel: -1,
  lang: 'en',
  servername: '',
  serverurl: 'https://server.adminweb.eu/',
  publickey: '',
  appsecret: '',
  sid: '',
  environment: 'prod',
  registryurl: 'https://registry.hypatia-learning.org/',
    /* Should be the same i both adminweb and user*/
  services: new Set(),
  engageActivityData: new Map()
}
