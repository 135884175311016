// import { ref, computed } from "vue";
import { defineStore } from 'pinia'

import { adminState } from './adminstore/adminState.js'
import { adminGetters } from './adminstore/adminGetters.js'
import { adminActions } from './adminstore/adminActions.js'

export const useAdminStore = defineStore('adminstore', {
  state: () => adminState,
  getters: adminGetters,
  actions: adminActions
})
