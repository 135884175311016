<template>
  <v-snackbar
    :model-value="show"
    location="center center"
    :timeout="timeoutValue"
    multi-line
    color="var(--color-brand-100)"
    raised="20"
    open-delay="500"
    :close-on-content-click="true"
    @update:modelValue="snackbarTimeout()"
  >
    <v-card flat color="var(--color-brand-100)">
      <v-card-title>{{ snackbarMessage }}</v-card-title>
    </v-card>
  </v-snackbar>
</template>

<script>
  export default {
    name: 'HlSnackbar',
    components: {},
    props: {
      snackbarMessage: {
        type: String,
        required: true,
        default: 'NO VALUE ERR: 9123jd82a5'
      },
      show: {
        type: Boolean,
        required: true,
        default: true
      }
    },
    setup() {},
    data() {
      return {
        timeoutValue: 4000
      }
    },
    beforeUpdate() {
      console.log('BEFORE UDPATE GOT CALLED (%s)', this.show)
    },
    mounted() {
      console.log('HL SNACKBAR MOUNTED (%s)', this.show)
    },
    methods: {
      snackbarTimeout() {
        console.log('snackbarTimeout(%s)', this.show)
        if (this.show) {
          this.$emit('timeout')
        }
      },
      getShowValue() {
        console.log('GET SHOW VALUE (%s)', this.show)
        return this.show
      }
    }
  }
</script>
