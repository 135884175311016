<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <span class="infoBtn">
    <v-btn
      icon
      :loading="loading"
      light
      :color="color"
      @click.stop="openDialog()"
    >
      <v-icon> {{ $t('icon.help') }} </v-icon>
      <v-tooltip
        activator="parent"
        location="bottom"
        open-delay="750"
        close-delay="500"
      >
        <span>{{ $t('tooltip.help') }}</span>
      </v-tooltip>
      <v-dialog
        v-model="showInfo"
        :max-width="getMaxWidth()"
        :max-height="getMaxHeight()"
        :eager="false"
        open-delay="300"
        close-delay="250"
        :scrollable="true"
        @click:outside="closeDialog()"
      >
        <v-card
          v-if="gotData"
          :max-width="getMaxWidth()"
          :max-height="getMaxHeight()"
        >
          <v-toolbar
            color="var(--color-third-header)"
            dark
            style="margin-bottom: 2.5vh"
          >
            <v-toolbar-title>{{ $t('help.headerhelp') }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn icon @click="closeHelpWindow()">
                <v-icon> {{ $t('icon.close') }}</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text v-if="gotURLinProperty" v-html="htmlDoc" />
          <v-card-text v-else>{{ getText() }}</v-card-text>
        </v-card>
        <WaitData v-else />
      </v-dialog>
    </v-btn>
  </span>
</template>
<script>
import { useAdminStore } from '@/stores/adminstore.js'

export default {
  name: 'HelpInformation',
  components: {
    WaitData: () => import('@/components/WaitData.vue')
  },
  props: {
    postfix: { type: String, default: '' },
    color: { type: String, default: 'var(--color-help-icon)' }
  },
  setup() {
    const store = useAdminStore()
    return {
      // you can return the whole store instance to use it in the template
      store
    }
  },
  data() {
    return {
      showInfo: false,
      gotData: false,
      loading: false,
      fetchData: false,
      gotURLinProperty: false,
      htmlDoc: ''
    }
  },
  methods: {
    getMaxWidth() {
      return '80vw'
    },
    getMaxHeight() {
      return '80vh'
    },
    closeHelpWindow() {
      this.loading = false
      this.showInfo = false
    },
    openDialog() {
      console.log('===== ROUTER ID (%s)', this.$route.name.toLowerCase())
      this.loading = true
      this.getURLData()
    },
    closeDialog() {
      //  console.log('CLOSE DIALOG GOT CALLED !')
      this.htmlDoc = ''
      this.gotURLinProperty = false
      this.showInfo = false
      this.gotData = false
      this.loading = false
      this.gotURLinProperty = false
      this.htmlDoc = ''
    },
    getText() {
      let router = this.$route.name.toLowerCase()
      let pf = ''
      if (this.postfix) {
        pf = this.postfix
      }
      let i18Ntext = 'help.' + router + pf
      console.log('=== I18Ntext (%s) (%s)', i18Ntext, this.$t(i18Ntext))
      this.gotData = true
      return this.$t(i18Ntext)
    },
    getURLData() {
      let potentialUrl = this.getText()
      console.log(
        'POTENTEIALURL (%s) (%s)',
        potentialUrl,
        potentialUrl.substr(0, 4)
      )
      if (potentialUrl.substr(0, 4) === 'http') {
        this.gotURLinProperty = true
      } else {
        this.fetchData = false
      }
      console.log('GOT URL VALUE (%s)', potentialUrl)
      let payload = {
        url: potentialUrl
      }
      console.log('PINIA TEST (%s)', this.store.getTest)
      this.store.testPromise(payload).then((result) => {
        console.log('RESULT FROM TEST (%j)', result)
      })
      console.log('URL IN PROPERTY (%s)', this.gotURLinProperty)
      if (this.gotURLinProperty) {
        //        this.store.setDataFromInfoUrl(payload).then((result) => {
        this.store.setDatafrominfourl(payload).then((result) => {
          console.log('GET URL DATA RETURNED...')
          console.log(result)
          if (result.success) {
            this.gotData = true
            this.htmlDoc = result.html
            this.loading = false
            this.showInfo = true
          } else {
            this.gotData = true
            this.loading = false
            this.showInfo = false
          }
        })
      } else {
        this.gotData = true
        this.showInfo = true
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.infoBtn {
  padding: 0;
}
</style>
