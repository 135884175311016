import axios from 'axios'

import {encodeLoad, decodeLoad} from '@/lib/base64.js'

import {CreateHypatiaJWE} from '@/lib/createJWT'

import {useAdminStore} from '@/stores/adminstore.js'

import {base64} from 'base64-url'

// import { jwt } from 'jsonwebtoken'
import * as jose from 'jose'
import { useToast } from 'vue-toastification'
import i18n from '@/i18n/i18n-setup.js'
const { t } = i18n.global
const toast = useToast()

export const userActions = {
  setTest(text) {
    console.log('set TEST got called (%s)', text)
    this.test = text
  },
  setLoggedIn(status) {
    this.loggedin = status
  },
  setDisplayName(name) {
    this.displayname = name
  },
  setNotRegistered(registered) {
    this.notregistered = registered
  },
  setPrimaryUserSelected(selected) {
    this.primaryselected = selected
  },
  setPrimaryUserData(payload) {
    this.primaryuser = payload
  },
  async googleLogin(payload) {
    console.log('GOOGLE LOGIN got called !')
    console.log(JSON.stringify(payload))
    return new Promise((resolve, reject) => {
      const adminStore = useAdminStore()
      this.accesstoken = payload.bearerToken
      this.accesstokenexpires = payload.expires
      this.idtoken = payload.id_token
      // localStorage.setItem('tokenexpire', payload.expires)
      this.displayname = payload.userName
      this.userid = payload.userid // Google email
      this.email = payload.email
      this.affiliation = 'KardeMEMAS'
      payload.affiliation = this.affiliation
      // context.dispatch('fixServerURL')
      payload.encodedAccessToken = payload.bearerToken
      // payload.eduPersonAffiliation = 'KardeMEMAS'
      // let publicKey = context.getters.getPublicKey // for client use
      //    let privateKey = context.getters.getPrivateKey // for serveruse !
      createJwtTokenForClient(payload)
        .then((result) => {
          console.info('GOOGLE LOING / JWTTOKEN ')
          console.log(result)
          // last part of the token is the secret key..
          // context.commit('setAcessToken', result.accessToken)
          this.accesstoken = result
          this.serveraccesstoken = result.accessToken
          this.publickey = result.publicKey
          this.appsecret = result.secret
          adminStore.sid = result.sid
          this.accesstokenexpires = payload.expires
          this.loggedin = true
          console.log('createJwtTokenForClient returned')
          console.log(result)
          //  context.dispatch('setMunicipalityNIN', norEduOrgNin.municipalityNorEduOrgNin)
          //  context.dispatch('setSchoolNIN', norEduOrgNin.schoolNorEduOrgNin)
          resolve({
            success: true,
            error: false
          })
        })
        .catch((error) => {
          console.error('*** ERROR *** createJWT Token For Client had some problems : ' + error)
          reject(new Error('*** ERROR *** createJWT Token For Client had some problems : ' + error))
        })
    })
  },
  async findSecondaryUser(email) {
    console.log('findSecondary user got called (%s)', email)
    const adminStore = useAdminStore()
    return new Promise((resolve) => {
      let load = `{
        query(func: eq(su_email,"${email}")) @cascade {
           uid
           su_email
           su_displayName
        }
    } `
      let pLoad = {
        query: encodeLoad(load),
        p1: adminStore.sid,
        gogl: true
      }
      let instance = adminStore.getAxiosConnection('dgraph-api/')
      instance
        .post('dquery/findSecondaryUser', pLoad)
        .then((response) => {
          console.log('Find secondary user respons (%j)', response)
          resolve({success: true, data: response.data.dgraphresult})
        })
        .catch((err) => {
          console.log('** ERROR ** Find secondary user respons (%j)', err)
          toast.error(t('toast.connectionerror'))
          resolve({
            success: false,
            error: true,
            errMsg: err
          })
        }) // then
    }) // promise
  },
  async getSecondaryUser() {
    console.log('getSecondary user got called (%s)', this.email)
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      let load = `{
        query(func: eq(su_email,"${this.email}")) {
           uid
           su_name
           su_email
           su_picture
           su_displayName
           su_created
           su_address
           su_postcode
           su_place
           su_country
           su_phone
           su_skype
           su_agrementtype
           su_relation_type
           su_emergency_contact
           su_emergency_phone
           su_preferences
           su_hasPrimaryUser {
            uid
           }
        }
    } `
      let pLoad = {
        query: encodeLoad(load),
        p1: adminStore.sid,
        gogl: true
      }
      let instance = adminStore.getAxiosConnection('dgraph-api/')
      instance.post('dquery/getSecondaryUser', pLoad).then((response) => {
        console.log('Find secondary user respons (%j)', response.data)
        this.secondaryuser = response.data.dgraphresult[0]
        this.setEngageUser(response.data.dgraphresult[0])
        resolve({success: true, data: response.data.dgraphresult[0]})
      }) // then
    }) // promise
  },
  async getAllSecondaryUsers() {
    console.log('getSecondary user got called (%s)', this.email)
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      let load = `{
        query(func: has(su_email)) {
           uid
           su_name
           su_email
           su_agrementtype
        }
    } `
      let pLoad = {
        query: encodeLoad(load),
        p1: adminStore.sid,
        gogl: true
      }
      let instance = adminStore.getAxiosConnection('dgraph-api/')
      instance.post('dquery/getSecondaryUser', pLoad).then((response) => {
        console.log('Find secondary user respons (%j)', response.data)
         resolve({success: true, data: response.data.dgraphresult})
      }) // then
    }) // promise
  },
  async deleteSecondaryUser(payload) {
    console.log('deleteSecondary user got called (%j)', payload)
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      let load = `<${payload.uid}> * * . \n`
      let pLoad = {
        mutate: encodeLoad(load),
        p1: adminStore.sid,
        gogl: true
      }
      let instance = adminStore.getAxiosConnection('dgraph-api/')
      instance
        .post('ddeletenquad/secondaryuser', pLoad)
        .then((result) => {
          console.log('deleteSecondary returned ', result)
          resolve({success: true, data: result})
        })
        .catch((err) => {
          console.log('** ERROR ** deleteSecondary returned ', err)
          resolve({
            success: false,
            error: true,
            errMsg: err
          })
        }) // then
    }) // promise
  },
  setPrimaryUser(user) {
    this.primaryuser = {}
    this.primaryuser = user
  },
  async deleteRegularVisitorData(rvUser) {
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      let pUser = this.primaryuser
      console.log('primary user (%j)', pUser)
      console.log('RV user (%j)', rvUser)
      let deleteLoad = `<${rvUser.uid}> * * . \n` + `<${pUser.uid}> <pu_hasRegularVisitor> <${rvUser.uid}> . \n`
      let pLoad = {
        mutate: encodeLoad(deleteLoad),
        p1: adminStore.sid,
        gogl: true
      }
      let instance = adminStore.getAxiosConnection('dgraph-api/')
      instance
        .post('ddeletenquad/regularvisitor', pLoad)
        .then((result) => {
          console.log('delete PRimary returned ', result)
          this.updatePrimaryUser().then(() => {
            resolve({
              success: true,
              data: result.data.dgraphresult
            })
          })
        })
        .catch((error) => {
          console.log('*** ERROR *** delete primary had some problems..')
          console.log(error)
          resolve({
            success: false,
            error: true,
            errorMsg: error.toString()
          })
        })
    }) // promise
  },
  async deletePrimaryUser(pUser) {
    console.log('DELET primary user (%s)', pUser.uid)
    /*
    @TODO sjekk for om bruker har data som kalender og faste besøkende bilder osv.
    Hvis så må det bekreftes at også disse også vil bli slettet.
    Hvis kun brukerdata - er det fritt frem foor å slette...
    */
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      let sUser = this.secondaryuser
      let deleteLoad = `<${pUser.uid}> * * . \n` + `<${sUser.uid}> <su_hasPrimaryUser> <${pUser.uid}> . \n`
      let pLoad = {
        mutate: encodeLoad(deleteLoad),
        p1: adminStore.sid,
        gogl: true
      }
      let instance = adminStore.getAxiosConnection('dgraph-api/')
      instance
        .post('ddeletenquad/primaryuser', pLoad)
        .then((result) => {
          console.log('delete PRimary returned ', result)
          this.updatePrimaryUser().then(() => {
            resolve({
              success: true,
              data: result.data.dgraphresult
            })
          })
        })
        .catch((error) => {
          console.log('*** ERROR *** delete primary had some problems..')
          console.log(error)
          resolve({
            success: false,
            error: true,
            errorMsg: error.toString()
          })
        })
    }) // promise
  },
  async getPrimaryUser() {
    console.log('getPrimaryUser user got called (%s)', this.email)
    let secondaryUser = this.secondaryuser
    console.log(secondaryUser)
    // let email = secondaryUser.su_email
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      if (secondaryUser.su_hasPrimaryUser || false) {
        let query = `{
          var(func: eq(su_email,"${this.email}")) {
             su_hasPrimaryUser {
              pUid as uid
             }
          }
          query(func: uid(pUid)) {
            uid
            pu_name
            pu_displayName
            pu_image
            pu_email
            pu_phone
            pu_skype
            pu_picture
            pu_postcode
            pu_address
            pu_place
            pu_country
            pu_language
            pu_created
            pu_userid
            pu_memas_login_code
            pu_hasCalendar {
              uid
            }
            pu_hasRegularVisitor {
              uid
              rv_name
              rv_phone
              rv_image
            }
            pu_hasServices {
              uid
              service_title
              service_id
              service_visible
            }
          }
      } `
        let pLoad = {
          query: encodeLoad(query),
          p1: adminStore.sid,
          gogl: true
        }
        // console.log(query)
        let instance = adminStore.getAxiosConnection('dgraph-api/')
        instance.post('dquery/primaryUsers', pLoad).then((response) => {
          console.log(response)
          let responseData = response.data
          console.log('get primary user respons (%j)', responseData.dgraphresult)
          this.setPrimaryUserData(responseData.dgraphresult)
          resolve({success: true, data: responseData.dgraphresult})
        }) // then
      } else {
        resolve({success: true, data: []})
      }
    }) // promise
  },
  async updatePrimaryUser() {
    console.log('updatePrimaryUser user got called (%s)', this.primaryuser.uid)
    let pUser = this.primaryuser
    // console.log(secondaryUser)
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      let query = `{
          query(func: uid(${pUser.uid})) {
            uid
            pu_name
            pu_displayName
            pu_image
            pu_email
            pu_phone
            pu_skype
            pu_picture
            pu_postcode
            pu_address
            pu_place
            pu_country
            pu_language
            pu_created
            pu_userid
            pu_memas_login_code
            pu_hasCalendar {
              uid
            }
            pu_hasRegularVisitor {
              uid
              rv_name
              rv_phone
              rv_image
            }
            pu_hasServices {
              uid
              service_title
              service_id
              service_visible
            }
          }
      } `
      let pLoad = {
        query: encodeLoad(query),
        p1: adminStore.sid,
        gogl: true
      }
      console.log(query)
      let instance = adminStore.getAxiosConnection('dgraph-api/')
      instance.post('dquery/updatePrimaryUsers', pLoad).then((response) => {
        console.log(response)
        let responseData = response.data
        console.log('update primary user respons (%j)', responseData.dgraphresult)
        this.primaryuser = responseData.dgraphresult[0]
        resolve({success: true, data: responseData.dgraphresult[0]})
      }) // then
    }) // promise
  },
  async processJWT(token) {
    console.log('processJWT got called !')
    return new Promise((resolve) => {
      console.log('WE HAVE GOOGLE AUTH CLIENT')
      let jwtData = jose.decodeJwt(token)
      console.log('JWT DATA IS (%j)', jwtData)
      let jwtComplete = token.split('.')

      let payload = {
        userEmail: jwtData.email,
        bearerToken: token,
        id_token: jwtComplete[2],
        expires: jwtData.exp,
        signedIn: jwtData.email_verified,
        domain: jwtData.hd,
        email: jwtData.email,
        //          userid: currentUser.Ot['$U'],
        userid: jwtData.email,
        userName: jwtData.name
      }
      this.googleLogin(payload).then((result) => {
        console.log('googleLogin returned (%j)', result)
        resolve(result)
      })
    }) // promise
  },
  callback(payload) {
    console.log('CALLBACK got called !' + JSON.stringify(payload))
    return new Promise((resolve) => {
      jose.jwtDecrypt(payload).then((jwtData) => {
        console.log('JWT DATA IS (%j)', jwtData)
      })

      resolve({success: true})
      /*
      appAuth.checkForAuthorizationResponse().then((res) => {
        console.log(
          '====== GOT checkForAuthorizationResponse ====== (' + res + ')'
        )
        appAuth.setCodeStatus(payload.callbackCode, payload.callbackState)
        appAuth
          .fetchServiceConfigurationToken(
            payload.callbackCode,
            payload.callbackState
          )
          .then((result) => {
            console.log('==== CALLBACK RESULT : ')
            console.log(result)
            let accessToken = result.accessToken
            let tokenExpires = result.expiresIn
            let idToken = result.idToken
            context.commit('setAccessToken', accessToken)
            context.commit('setAccessTokenExpires', tokenExpires)
            context.commit('setIdToken', idToken)
            localStorage.setItem('tokenexpire', tokenExpires)
            resolve(idToken)
          })
          .catch((error) => {
            console.error('*** ERROR *** CALLBACK : ' + error)
            reject(
              new Error({
                error: error
              })
            )
          })
      }) // checkForAuthorizationResponse
      */
    }) // promise
  },
  getRandomKey(numDigits) {
    let randomKey = ''
    let randomMax = randomDigits.length
    // console.log('RANDOM MAX IS (%s)', randomMax)
    if (numDigits) {
      for (let i = 0; i < numDigits; i++) {
        let random = Math.floor(Math.random() * randomMax)
        // console.log('RANDOM IS (%s) (%s)', random, randomDigits[random])
        randomKey = randomKey + randomDigits[random]
      }
    } else {
      randomKey = this.getRandomKey(4)
    }
    return randomKey
  },
  async saveSecondaryUser(payload) {
    console.log('saveSecondaryUser got called ', payload)
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(payload)),
        p1: adminStore.sid,
        gogl: true
      }
      const instance = adminStore.getAxiosConnection('memas/')
      instance.post('saveSecondaryUser/newSecondaryUser', pLoad).then((result) => {
        console.log('NEW/UPDATED Secondary User is returned(%j)', result)
        payload.uid = result.data.userid
        this.getSecondaryUser() 
      //  this.secondaryuser = payload
        resolve({success: true})
      })
    }) // promise
  },
  async savePrimaryUser(payload) {
    console.log('savePrimaryUser got called (%j)', payload)
    let secondaryUser = this.secondaryuser
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      payload.secondaryUserUid = secondaryUser.uid
      console.log(payload)
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(payload)),
        p1: adminStore.sid,
        gogl: true
      }
      const instance = adminStore.getAxiosConnection('memas/')
      instance.post('savePrimaryUser/newPrimaryUser', pLoad).then((result) => {
        this.primaryuser = payload
        console.log('NEW Primary User is returned(%j)', result)
        resolve({success: true})
      })
    }) // promise
  },
  async saveRegularVisitor(payload) {
    console.log('saveRegularVisitor got called ')
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      console.log(payload)
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(payload)),
        p1: adminStore.sid,
        gogl: true
      }
      const instance = adminStore.getAxiosConnection('memas/')
      instance.post('saveRegularVisitor/newRegularVisitor', pLoad).then((result) => {
        console.log('NEW saveRegularVisitor is returned(%j)', result)
        this.updatePrimaryUser().then((res) => {
          resolve({success: true, msg: 'with updated primary user'})
        })
      })
    }) // promise
  },
  async saveUserServices(payload) {
    console.log('saveUserServices got called ')
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      let puser = this.primaryuser
      let load = {}
      load.puser = puser
      load.services = payload
      console.log(payload)
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(load)),
        p1: adminStore.sid,
        gogl: true
      }
      const instance = adminStore.getAxiosConnection('memas/')
      instance.post('saveUserServices/updateUserServices', pLoad).then((result) => {
        console.log('NEW saveRegularVisitor is returned(%j)', result)
        this.updatePrimaryUser().then((res) => {
          resolve({success: true, msg: 'with updated primary user'})
        })
      })
    }) // promise
  },
  async addAdditionalSecondaryUser(email) {
    return new Promise((resolve) => {
      let payload = {
        secondaryUserEmail: email,
        primaryUserUid: this.primaryuser.uid
      }
      const adminStore = useAdminStore()
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(payload)),
        p1: adminStore.sid,
        gogl: true
      }
      const instance = adminStore.getAxiosConnection('memas/')
      instance.post('addAdditionalSecondaryUser/additionalSecondaryUser', pLoad).then((result) => {
        console.log('addAdditionalSecondaryUser is returned(%j)', result)
        if (result.data.success) {
          resolve({success: true})
        } else if (result.data.noresult) {
          resolve({success: false, noresult: true})
        } else {
          resolve({success: false, error: true})
        }
      })
    })
  },
  async getAdditionalUsersForPrimary() {
    let adminStore = useAdminStore()
    return new Promise((resolve) => {
      let pUser = this.primaryuser
      let query = `{
        query(func: uid(${pUser.uid})) {
          uid
          secondaryUsers: ~su_hasPrimaryUser {
						uid
            su_name
            su_phone
            su_skype
            su_email
          }
         }
        }`
      let pLoad = {
        query: encodeLoad(query),
        p1: adminStore.sid,
        gogl: true
      }
      console.log(query)
      let instance = adminStore.getAxiosConnection('dgraph-api/')
      instance.post('dquery/getAdditionalUsers', pLoad).then((response) => {
        console.log(response)
        console.log('getAdditionalUsersForPrimary respons (%j)', response.data)
        let responseData = response.data
        if (responseData.success) {
          let rData = responseData.dgraphresult[0] || {}
          resolve({success: true, data: rData.secondaryUsers})
        } else {
          resolve({success: false, error: true, errMsg: response})
        }
      }) // then
    }) // promise
  },
  async getEngageData(payload) {
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(payload)),
        p1: adminStore.sid,
        gogl: true
      }
      const instance = adminStore.getAxiosConnection('memas/')
      instance.post('getEngageId/engageEmail', pLoad).then((result) => {
        console.log('getEngageData is returned(%j)', result)
        if (result.data.success) {
          resolve({success: true, engageId: result.data.engageId})
        } else {
          resolve(result.data)
        }
      })
    }) // promise
  },
  async savePrimaryHelpVideo(payload) {
    console.log('savePrimaryHelpVideo got called (%j)', payload)
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(payload)),
        p1: adminStore.sid,
        gogl: true
      }
      const instance = adminStore.getAxiosConnection('memas/')
      instance.post('savePrimaryHelpVideo/newHelpVideo', pLoad).then((result) => {
        console.log('NEW savePrimaryHelpVideo is returned(%j)', result)
        this.updatePrimaryUser().then((res) => {
          resolve({success: true, msg: 'with updated primary help video user'})
        })
      })
    }) // promise
  },
  async getPrimaryHelpVideos() {
    console.log('getPrimaryHelpVideos user got called (%s)', this.primaryuser.uid)
    let pUser = this.primaryuser
    // console.log(secondaryUser)
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      let query = `{
        query(func: uid(${pUser.uid})) {
          uid
          dailyHelp: pu_hasDailyHelp {
            uid
            dh_title
            dh_video
            dh_videoimage
            dh_created
          }
        }
      } `
      let pLoad = {
        query: encodeLoad(query),
        p1: adminStore.sid,
        gogl: true
      }
      console.log(query)
      let instance = adminStore.getAxiosConnection('dgraph-api/')
      instance.post('dquery/getPrimaryHelpVideos', pLoad).then((response) => {
        console.log(response)
        let responseData = response.data
        console.log('getPrimaryHelpVideos respons (%j)', responseData.dgraphresult)
        resolve({success: true, data: responseData.dgraphresult})
      }) // then
    }) // promise
  },
  async deletePrimaryHelpVideo(payload) {
    console.log('delte Primary Help Video got called (%j)', payload)
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      let pUser = this.primaryuser
      console.log('primary help Video (%j)', pUser)
      let deleteLoad = `<${payload.uid}> * * . \n` + `<${pUser.uid}> <pu_hasDailyHelp> <${payload.uid}> . \n`
      let pLoad = {
        mutate: encodeLoad(deleteLoad),
        p1: adminStore.sid,
        gogl: true
      }
      let instance = adminStore.getAxiosConnection('dgraph-api/')
      instance
        .post('ddeletenquad/helpVideo', pLoad)
        .then((result) => {
          console.log('delete Help Video returned ', result)
          this.updatePrimaryUser().then(() => {
            resolve({
              success: true,
              data: result.data.dgraphresult
            })
          })
        })
        .catch((error) => {
          console.log('*** ERROR *** delete help video had some problems..')
          console.log(error)
          resolve({
            success: false,
            error: true,
            errorMsg: error.toString()
          })
        })
    }) // promise
  },
  async savePrimaryWorkVideo(payload) {
    console.log('savePrimaryWorkVideo got called (%j)', payload)
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(payload)),
        p1: adminStore.sid,
        gogl: true
      }
      const instance = adminStore.getAxiosConnection('memas/')
      instance.post('savePrimaryWorkVideo/newWorkVideo', pLoad).then((result) => {
        console.log('NEW savePrimaryWorkVideo is returned(%j)', result)
        this.updatePrimaryUser().then((res) => {
          resolve({success: true, msg: 'with updated primary work video user'})
        })
      })
    }) // promise
  },
  async getPrimaryWorkVideos() {
    console.log('getPrimaryWorkVideos user got called (%s)', this.primaryuser.uid)
    let pUser = this.primaryuser
    // console.log(secondaryUser)
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      let query = `{
        query(func: uid(${pUser.uid})) {
          uid
          dailyWork: pu_hasWorkHelp {
            uid
            dw_title
            dw_video
            dw_videoimage
            dw_created
          }
        }
      } `
      let pLoad = {
        query: encodeLoad(query),
        p1: adminStore.sid,
        gogl: true
      }
      console.log(query)
      let instance = adminStore.getAxiosConnection('dgraph-api/')
      instance.post('dquery/getPrimaryWorkVideos', pLoad).then((response) => {
        console.log(response)
        let responseData = response.data
        console.log('getPrimaryWorkVideos respons (%j)', responseData.dgraphresult)
        resolve({success: true, data: responseData.dgraphresult})
      }) // then
    }) // promise
  },
  async deletePrimaryWorkVideo(payload) {
    console.log('delete Primary Work Video got called (%j)', payload)
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      let pUser = this.primaryuser
      console.log('primary work Video (%j)', pUser)
      let deleteLoad = `<${payload.uid}> * * . \n` + `<${pUser.uid}> <pu_hasWorkHelp> <${payload.uid}> . \n`
      let pLoad = {
        mutate: encodeLoad(deleteLoad),
        p1: adminStore.sid,
        gogl: true
      }
      let instance = adminStore.getAxiosConnection('dgraph-api/')
      instance
        .post('ddeletenquad/workVideo', pLoad)
        .then((result) => {
          console.log('delete Work Video returned ', result)
          this.updatePrimaryUser().then(() => {
            resolve({
              success: true,
              data: result.data.dgraphresult
            })
          })
        })
        .catch((error) => {
          console.log('*** ERROR *** delete work video had some problems..')
          console.log(error)
          resolve({
            success: false,
            error: true,
            errorMsg: error.toString()
          })
        })
    }) // promise
  },

  async logoutUser() {
    return new Promise((resolve) => {
      this.loggedin = false
      this.serveraccesstoken = ''
      this.accesstoken = ''
      this.displayname = ''
      this.userid = ''
      this.idtoken = ''
      this.accesstokenexpires
      this.email = ''
      this.primaryuser = {}
      this.secondaryuser = {}
      resolve({success: true})
    }) // promise
  },
  async getSelfReports() {
    let pUser = this.primaryuser
    console.log('getSelfReports user got called (%s)', pUser.pu_displayName)
    return new Promise((resolve) => {
      let pUid = pUser.uid
      const adminStore = useAdminStore()
      let load = `{
        query(func: uid("${pUid}")) {
           uid
           pu_hasSelfReporting {
            sr_testType
            sr_testTime
            sr_questions {
              srq_question_number
              srq_value
              duration as srq_duration
            }
            totalDuration: sum(val(duration))
           }
        }
    } `
      let pLoad = {
        query: encodeLoad(load),
        p1: adminStore.sid,
        gogl: true
      }
      let instance = adminStore.getAxiosConnection('dgraph-api/')
      instance.post('dquery/getSelfReports', pLoad).then((response) => {
        console.log('getSelfReports respons (%j)', response.data)
        resolve({success: true, data: response.data.dgraphresult[0]})
      }) // then
    }) // promise
  },
  getUserServices() {
    let pUser = this.primaryuser
    let services = pUser.pu_hasServices || []
    return services
  },
  setEngageUser(payload) {
    let aStore = useAdminStore()
    let locale = aStore.lang
    console.log('setEngageUser got called (%s) (%j)', locale, payload)
    let user = payload
    if (user.su_agrementtype.length < 6) {
       this.engageuser = { engageuser: false }
    } else {
    console.log('ENGAGE USER (%j)', user)
      let agreementType = user.su_agrementtype
      let memasAgreement = agreementType.substring(0, 6)
      let engageLanguage = agreementType.substring(6, agreementType.length)
      console.log('memasAgreement (%s)  (%s)', memasAgreement,engageLanguage)
      let lang2Digit = get2DigitLangCodes(locale)
      let mAgreement = `${memasAgreement}${lang2Digit}`
      console.log('mAgreement (%s) (%s)', mAgreement, memasAgreement.toUpperCase() === 'ENGAGE')
    if (memasAgreement.toUpperCase() === 'ENGAGE') {
        this.engageuser = { engageuser: true, lang: engageLanguage }
      } else {
        this.engageuser = { engageuser: false }
      } 
  }
}
}

function get2DigitLangCodes(lang) {
  switch (lang) {
    case 'nb-NO':
    case 'nn-NO':
    case 'nb':
      return 'nb'
    default:
      return 'en'
  }
}

const randomDigits = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'J',
  'K',
  'L',
  'M',
  'N',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9'
]

async function createJwtTokenForClient(payload) {
  console.log('createJwtTokenForClient got called')
  console.log(payload)
  return new Promise((resolve, reject) => {
    try {
      const adminStore = useAdminStore()
      let tokenPayload = {
        userid: payload.userid,
        affiliation: payload.affilitation,
        accessToken: payload.encodedAccessToken,
        email: payload.email || 'NO EMAIL',
        payload: payload
      }
      console.log('tokenPayload : ', tokenPayload)
      getServerSecret()
        .then((result) => {
          //   console.log('GET SERVER SECRET RETURNED : ')
          //   console.log(result)
          let publicKey = result.publicKey
          let serverSecret = result.secret
          adminStore.sid = result.sid
          tokenPayload.serverSecret = result.secret
          tokenPayload.sessionid = adminStore.sid
          let hypJWE = new CreateHypatiaJWE(tokenPayload, serverSecret, null, publicKey)
          let encrypted = ''

          hypJWE
            .setUp()
            .then(() => {
              hypJWE
                .encrypt(JSON.stringify(tokenPayload))
                .then((encrypts) => {
                  encrypted = encrypts
                  //      console.log(tokenPayload)
                  //      console.log('==== ENCRYPTED ====')
                  //      console.log(encrypted)
                })
                .then(() => {
                  let cryptedPayload = {
                    cryptedPayload: encrypted
                  }
                  //     console.log('CRYPTED PAYLOAD')
                  // console.log(cryptedPayload)
                  hypJWE.sign(cryptedPayload, serverSecret).then((token) => {
                    let returnPayload = {
                      encryptedPayload: encrypted,
                      accessToken: token,
                      publicKey: publicKey,
                      secret: serverSecret,
                      sid: adminStore.sid
                    }
                    resolve(returnPayload)
                  }) // sign
                }) // encrypt
                .catch((error) => {
                  console.error('*** ERROR *** problems encrypting the payload : ' + error)
                  reject({
                    error: true,
                    errorMsg: '*** ERROR *** problems encrypting the payload : ' + error
                  })
                }) // encrypt catch
            }) // setup
            .catch((error) => {
              console.error('*** ERROR *** hypJWE.setup had some problems', error)
              reject(new Error('*** ERROR *** hypJWE.setup had some problems', error))
            })
          /*
          hypJWE.decrypt(encrypted)
            .then(decrypted => {
              console.log('==== DECRYPTED ====')
              console.log(decypted)
            })
            */
        }) // secret
        .catch((error) => {
          console.error('*** ERROR *** problems getting the secret from the server : ' + error)
          reject({
            error: true,
            errorMsg: '*** ERROR *** problems getting the secret from the server : ' + error
          })
        }) // getServerSecret - catch
    } catch (error) {
      console.error('*** ERROR **** createJwtTokenForClient, had some problems ' + error)
      reject(
        new Error({
          error: error
        })
      )
    }
  }) // promise
}

async function getServerSecret() {
  console.log('GET SERVER Secret GOT CALLED !')
  console.log('=== getServerSecret ')
  return new Promise((resolve, reject) => {
    const adminStore = useAdminStore()
    // let accessClientToken = context.getters.getIdToken
    // console.error('========= idToken !!: ' + accessClientToken)
    // let serverUrl = context.getters.getServerUrl // No context outside action-vuex-store context...
    const sysinfo = adminStore.getAxiosConnection2(adminStore.serverurl, 'sysinfo/')
    sysinfo
      .get('jwtsecret')
      .then((result) => {
        console.log('JwtSecret er: (' + JSON.stringify(result.data.secret) + ')')
        console.log('getServerSecret returned (%J)', result)
        //  localStorage.setItem('hypsaserversecret', result.data.secret)
        console.log('publicKey: ' + result.data.publicKey)
        resolve(result.data) // {'secret': something }
      })
      .catch((error) => {
        console.error('*** ERROR *** getServerSECRET had some errors:', error)
        reject(
          new Error({
            error: error
          })
        )
      })
  }) // promise
}
